


















































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import {
  ProductBrandDto,
  ProductCategoryDto,
  ProductSkuDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import { ElForm } from "element-ui/types/form";
import { createNgTree, INgNode } from "@/utils/tree";

@Component({
  name: "SelectOrderCommodity",
  components: { PagedTableView },
})
export default class SelectOrderCommodity extends Vue {
  @Ref() readonly dataForm!: ElForm;

  queryForm = {
    number: undefined,
    name: undefined,
    parentIdPath: undefined,
    brandId: undefined,
  };
  show = false;
  loading = true;
  form: ProductSkuDto = {};
  selectionList = [] as ProductSkuDto[];
  productSkus = [] as ProductSkuDto[];
  productBrandList: ProductBrandDto[] = [];
  categories: ProductCategoryDto[] = [];
  categoriesTree: INgNode<ProductCategoryDto>[] = [];
  defaultData: ProductSkuDto = {
    id: 0,
  };

  save() {
    // this.dataIds = [];
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "startOrderOf.atLeastOnePieceOfData"
        ) as string,
      });
      return;
    }
    this.$confirm(
      (this as any).$l.getLanguageText("startOrderOf.batchSelection") as string,
      (this as any).$l.getLanguageText("basic.tip") as string
    ).then(async () => {
      this.productSkus = this.selectionList;
      this.$emit("productSkus", this.productSkus);
      this.show = false;
    });
  }

  // 获取已选择数据
  getSelectionList(rows: ProductSkuDto[]) {
    this.selectionList = rows;
  }

  async created() {
    await this.fetchProductBrandList();
    await this.fetchCategories();
  }

  fetchProductBrandList() {
    api.productBrand.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.productBrandList = res!.items;
    });
  }

  // 获取表数据
  fetchData(params: any) {
    if (this.$route.params.code) {
      params.customerCode = this.$route.params.code;
    }
    return api.productSku.getAll(params);
  }

  fetchCategories() {
    api.productCategory.getAll({ maxResultCount: 1000 }).then((res) => {
      this.categories = res.items!;
      this.categoriesTree = createNgTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "fullName"
      );
    });
  }

  handleCategoriesChange(value: any) {
    this.queryForm!.parentIdPath = value![value.length - 1];
  }

  cancel() {
    this.show = false;
  }
}
