































































































































































































































































































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import {
  CustomerBillAddressMappingDto,
  CustomerDto,
  CustomerReceivingAddressMappingDto,
  OrderMainCreateOrUpdateDto,
  OrderPayMethod,
  OrderReceivingMethod,
  ProductSkuDto,
  ProductSkuStockDto,
  SelectListItem,
  SysSettingOrderDto,
  SysSettingTaxeDto,
  WarehouseSelfLiftinPointDto,
} from "@/api/appService";
import SelectOrderCommodity from "@/views/productSku/order/selectSku.vue";
import { ElForm } from "element-ui/types/form";
import { round } from "lodash";

interface ProductSkuExtend extends ProductSkuDto {
  quantity?: number;
  total: number;
}

// noinspection BadExpressionStatementJS
@Component({
  name: "startOrderOf",
  components: { SelectOrderCommodity },
})
export default class StartOrderOf extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly receivingForm!: ElForm;
  emailAddress?: any;
  customerCode?: any;
  detail: CustomerDto = {};
  loading = true;
  productSkuList: ProductSkuExtend[] = [];
  productSku: any = [];
  quantity: undefined;
  orderMainFrom: OrderMainCreateOrUpdateDto = {
    receivingMethod: OrderReceivingMethod.LogisticsDelivery,
  };
  allTotal?: number = 0;
  productCount?: number = 0;
  tranExpensesTotal?: number = 0;
  taxesQSTTotal?: number = 0;
  taxesGSTTotal?: number = 0;
  index = 0;
  orderReceivingMethodList: SelectListItem[] = [];
  warehouseSelfLiftinPointList = [] as WarehouseSelfLiftinPointDto[];
  receivingAddress: CustomerReceivingAddressMappingDto = {};
  billAddress: CustomerBillAddressMappingDto = {};
  sysSettingTaxe: SysSettingTaxeDto = {};
  sysSettingOrder: SysSettingOrderDto = {};
  showReceivingAddressTable = false;
  showBillAddressTable = false;
  billAddressList = [] as CustomerBillAddressMappingDto[];
  receivingAddressList = [] as CustomerReceivingAddressMappingDto[];
  multipleSelection = [] as any[];
  billAddressMultipleSelection = [] as any[];
  roleRule = {
    receivingMethod: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "startOrderOf.selectReceivingMethod"
        ) as string,
        trigger: "blur",
      },
    ],
    receivingAddressMappingId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "startOrderOf.selectReceivingAddressMappingId"
        ) as string,
      },
    ],
    warehouseSelfLiftinPointId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "startOrderOf.selectWarehouseSelfLiftingPointId"
        ) as string,
        trigger: "blur",
      },
    ],
    billAddressMappingId: [
      {
        required: true,
        pattern: /^[0-9]*$/,
        message: (this as any).$l.getLanguageText(
          "startOrderOf.selectBillAddressMappingId"
        ) as string,
        trigger: "blur",
      },
    ],
  };

  created() {
    if (this.$route.params.email && this.$route.params.code) {
      this.emailAddress = this.$route.params.email;
      this.customerCode = this.$route.params.code;
      this.fetchDetail();
      this.fetchOrderReceivingMethodList();
      this.fetchWarehouseSelfLiftinPointList();
      this.getSysSettingOrder();
    }
  }

  // 选择商品
  handleSelectCommodity() {
    (this.$refs.selectFrom as any).show = true;
  }

  async fetchReceivingAddressList() {
    await api.customerReceivingAddressMapping
      .getAll({ customerId: this.detail.id })
      .then((res) => {
        this.receivingAddressList =
          res.items?.sort(function (a, b) {
            return a.id! - b.id!;
          }) ?? [];
      });
  }

  // 切换收货地址
  handleSelectReceivingAddress() {
    this.showReceivingAddressTable = true;
    this.$nextTick(() => {
      if (
        this.multipleSelection.length <= 0 &&
        this.orderMainFrom.receivingAddressMappingId
      ) {
        (this.$refs.receivingAddressTable as any).clearSelection();
        this.receivingAddressList.forEach((row) => {
          if (row.isDefault === true) {
            (this.$refs.receivingAddressTable as any).toggleRowSelection(
              row,
              true
            );
          }
        });
      }
    });
  }

  async fetchBillAddressList() {
    await api.customerBillAddressMapping
      .getAll({ customerId: this.detail.id })
      .then((res) => {
        this.billAddressList =
          res.items?.sort(function (a, b) {
            return a.id! - b.id!;
          }) ?? [];
      });
  }

  // 切换发票地址
  handleSelectBillAddress() {
    this.showBillAddressTable = true;
    this.$nextTick(() => {
      if (
        this.billAddressMultipleSelection.length <= 0 &&
        this.orderMainFrom.billAddressMappingId
      ) {
        (this.$refs.billAddressTable as any).clearSelection();
        this.billAddressList.forEach((row) => {
          if (row.isDefault === true) {
            (this.$refs.billAddressTable as any).toggleRowSelection(row, true);
          }
        });
      }
    });
  }

  // 获取详情
  async fetchDetail() {
    await api.orderMain
      .preparePlaceOrderOnBehalf({
        body: {
          emailAddress: this.emailAddress,
          customerCode: this.customerCode,
        },
      })
      .then((res) => {
        this.loading = false;
        this.detail = { ...res };
        const receivingAddressList = res.receivingAddressList ?? [];

        let defaultReceivingAddress = receivingAddressList.find(
          (item) => item.isDefault
        );
        if (!defaultReceivingAddress && receivingAddressList.length > 0) {
          defaultReceivingAddress = receivingAddressList[0];
        }

        if (defaultReceivingAddress) {
          this.receivingAddress = defaultReceivingAddress;
          this.getSysSettingTaxe(Number(this.receivingAddress.provinceId));
          console.log(
            "initialize receivingAddressMappingId:",
            defaultReceivingAddress.id
          );
          this.orderMainFrom.receivingAddressMappingId =
            defaultReceivingAddress.id;
          console.log(
            "initialize receivingAddressMappingId after:",
            this.orderMainFrom.receivingAddressMappingId
          );
        }

        const billAddressList = res.billAddressList ?? [];
        let defaultBillAddress = billAddressList.find((item) => item.isDefault);
        if (!defaultBillAddress && billAddressList.length > 0) {
          defaultBillAddress = billAddressList[0];
        }

        if (defaultBillAddress) {
          this.billAddress = defaultBillAddress;
          console.log(
            "initialize billAddressMappingId:",
            defaultBillAddress.id
          );
          this.orderMainFrom.billAddressMappingId = defaultBillAddress.id;
          console.log(
            "initialize billAddressMappingId after:",
            this.orderMainFrom.billAddressMappingId
          );
        }
        this.fetchBillAddressList();
        this.fetchReceivingAddressList();
      });
  }

  getSelectionProductSkus(productSkuList: any) {
    console.log(productSkuList);
    if (productSkuList || productSkuList.length > 0) {
      console.log("ids=" + productSkuList);
      this.productSkuList = [];
      this.productSkuList = productSkuList;
    }
  }

  async fetchOrderReceivingMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderReceivingMethod" })
      .then((res) => {
        this.orderReceivingMethodList = res;
      });
  }

  async fetchWarehouseSelfLiftinPointList() {
    await api.warehouseSelfLiftinPoint
      .getAll({ pageSize: 65535 })
      .then((res) => {
        this.warehouseSelfLiftinPointList = res.items ?? [];
      });
  }

  getAddress(item: WarehouseSelfLiftinPointDto) {
    return (
      (item.fkProvince ? item.fkProvince.provinceName + "-" : "") +
      (item.fkCity ? item.fkCity.cityName + "-" : "") +
      item.address
    );
  }

  save(): void {
    (this.receivingForm as any).validate(async (valid: boolean) => {
      console.log(
        "initialize receivingAddressMappingId save validation:",
        this.orderMainFrom.receivingAddressMappingId
      );
      if (!valid) {
        return;
      }
      if (this.productSkuList && this.productSkuList.length > 0) {
        let quantityDetailList: ProductSkuStockDto[] = [];
        this.productSkuList.forEach((item) => {
          if (!item.quantity || item.quantity <= 0) {
            this.$message(
              (this as any).$l.getLanguageText(
                "startOrderOf.enterOrderQuantity"
              ) as string
            );
            return false;
          }
          quantityDetailList.push({
            id: item.id,
            quantity: item.quantity,
          });
          console.log(quantityDetailList);
        });
        if (this.productSkuList && this.productSkuList.length > 0) {
          this.orderMainFrom.detailList = quantityDetailList;
          console.log(this.orderMainFrom.detailList);
        } else {
          this.orderMainFrom.detailList = quantityDetailList;
        }
        if (
          this.orderMainFrom.detailList &&
          this.orderMainFrom.detailList.length > 0
        ) {
          await api.orderMain
            .doPlaceOrderOnBehalf({
              body: {
                customerId: this.detail.id,
                detailList: this.orderMainFrom.detailList,
                receivingMethod: this.orderMainFrom.receivingMethod,
                warehouseSelfLiftinPointId:
                  this.orderMainFrom.receivingMethod ===
                  OrderReceivingMethod.DoorToDoorSelfDelivery
                    ? this.orderMainFrom.warehouseSelfLiftinPointId
                    : undefined,
                receivingAddressMappingId:
                  this.orderMainFrom.receivingMethod ===
                  OrderReceivingMethod.LogisticsDelivery
                    ? this.orderMainFrom.receivingAddressMappingId
                    : undefined,
                billAddressMappingId: this.orderMainFrom.billAddressMappingId,
                payMethod: OrderPayMethod.ChequePayment,
              },
            })
            .then((res) => {
              if (res.isHasError) {
                this.$message.error(
                  res.errorMsg
                    ? res.errorMsg
                    : ((this as any).$l.getLanguageText(
                        "startOrderOf.orderFailed"
                      ) as string)
                );
              } else {
                this.$message.success(
                  (this as any).$l.getLanguageText(
                    "startOrderOf.success"
                  ) as string
                );
                this.$emit("onSave");
                this.$router.back();
              }
            });
        }
      }
    });
  }

  handleChange(val: number, index: number, row: ProductSkuExtend) {
    this.index = this.productSkuList.findIndex((item) => {
      if (item.id == row.id) {
        if (item.revisedFactoryPrice) {
          item.total = val * Number(item.revisedFactoryPrice);
        } else {
          item.total = val * Number(item.factoryPrice);
        }
        return true;
      }
    });
    let sum = 0;
    let count = 0;
    this.productSkuList.forEach((item) => {
      sum += Number(item.total);
      count += Number(item.quantity);
    });
    if (!isNaN(sum) && !isNaN(count)) {
      this.productCount = count;
      this.allTotal = sum;
    } else {
      this.productCount = row.quantity;
      this.allTotal = row.total;
    }
    console.log(this.allTotal);
  }

  handleDelete(index: number, row: ProductSkuDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.deleteTip") as string,
      (this as any).$l.getLanguageText("basic.tip") as string
    ).then(async () => {
      this.index = this.productSkuList.findIndex((item) => {
        if (item.id == row.id) {
          return true;
        }
      });
      this.productSkuList.splice(this.index, 1);
    });
  }

  handleSelectionChange(selection: any, row: any) {
    if (this.multipleSelection[0] == row) {
      this.multipleSelection = [];
      (this.$refs.receivingAddressTable as any).clearSelection();
    } else {
      this.multipleSelection = [row];
      (this.$refs.receivingAddressTable as any).clearSelection();
      (this.$refs.receivingAddressTable as any).toggleRowSelection(row, true);
    }
  }

  handleBillAddressSelectionChange(selection: any, row: any) {
    if (this.billAddressMultipleSelection[0] == row) {
      this.billAddressMultipleSelection = [];
      (this.$refs.billAddressTable as any).clearSelection();
    } else {
      this.billAddressMultipleSelection = [row];
      (this.$refs.billAddressTable as any).clearSelection();
      (this.$refs.billAddressTable as any).toggleRowSelection(row, true);
    }
  }

  confirmReceivingAddress() {
    this.receivingAddress = this.multipleSelection[0] ?? {};
    this.showReceivingAddressTable = false;
    this.orderMainFrom.receivingAddressMappingId = this.receivingAddress.id;
    this.getSysSettingTaxe(Number(this.receivingAddress.provinceId));
  }

  confirmBillAddress() {
    this.billAddress = this.billAddressMultipleSelection[0] ?? {};
    this.showBillAddressTable = false;
    this.orderMainFrom.billAddressMappingId = this.billAddress.id;
  }

  getSysSettingTaxe(provinceId: number) {
    if (provinceId > 0) {
      api.sysSettingTaxe
        .getByProvinceId({ provinceId: provinceId })
        .then((res) => {
          this.sysSettingTaxe = res;
        });
    }
  }

  getSysSettingOrder() {
    api.sysSettingOrder.getCurrent().then((res) => {
      this.sysSettingOrder = res;
    });
  }

  @Watch("allTotal")
  totalMoney(value: number) {
    if (this.orderMainFrom.receivingMethod == "DoorToDoorSelfDelivery") {
      this.tranExpensesTotal = 0;
    } else if (value > Number(this.sysSettingOrder.orderAmountThreshold)) {
      this.tranExpensesTotal = Number(
        this.sysSettingOrder.greaterThanTranExpenses
      );
    } else {
      this.tranExpensesTotal = Number(
        this.sysSettingOrder.lessThanOrEqualTranExpenses
      );
    }

    this.taxesGSTTotal = round(value * Number(this.sysSettingTaxe.taxesGST), 2);
    this.taxesQSTTotal = round(value * Number(this.sysSettingTaxe.taxesQST), 2);
  }

  getWarehouseSelfLiftinPoint(value: number) {
    this.warehouseSelfLiftinPointList.forEach((item) => {
      if (item.id == value) {
        this.getSysSettingTaxe(Number(item.provinceId));
      }
    });
  }

  private cancel() {
    this.$router.back();
  }
}
